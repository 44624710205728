import React from "react";
import { ThemeProvider } from "styled-components";

const colorSchemes = {
  white: { colorFront: "#191818", colorBack: "#f8f2ed" },
  black: { colorFront: "#fffffe", colorBack: "#191818" },
  blue: { colorBack: "#b9c2ce" },
  brown: { colorBack: "#e3cbb9" },
  green: { colorBack: "#dce1cd" },
  yellow: { colorBack: "#ffdd8b" }
};

export const ColorTheme = ({ children, color }) =>
  color in colorSchemes ? (
    <ThemeProvider theme={colorSchemes[color]}>{children}</ThemeProvider>
  ) : (
    <>{children}</>
  );
