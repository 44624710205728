import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../../utilities/media";

const StyledNavigation = styled.nav`
  text-transform: uppercase;
  text-shadow: 0 0 1.5em ${props => props.theme.colorBack};
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;

  ${media.md(
    css`
      flex-wrap: nowrap;
      margin: -2rem;
    `
  )}
`;

const NavListItem = styled.li`
  flex-basis: 50%;
  padding: 1rem;

  ${media.md(
    css`
      flex-basis: auto;
      padding: 2rem;
    `
  )}
`;

const NavLink = styled.a`
  text-decoration: none;
`;

const Navigation = ({ items = [] }) => (
  <StyledNavigation>
    <NavList>
      {items.map(({ to, text, lang }) => (
        <NavListItem key={text} lang={lang}>
          <NavLink href={to}>{text}</NavLink>
        </NavListItem>
      ))}
    </NavList>
  </StyledNavigation>
);

export default Navigation;
