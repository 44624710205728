import React from "react";
import styled, { css } from "styled-components";
import GatsbyImage from "gatsby-image";
import { ColorTheme } from "../../themes/ColorTheme";
import { headerFontProperties } from "../../../utilities/fonts";
import { media } from "../../../utilities/media";
import Container from "../../elements/Container";
import logo from "../../../assets/images/logo_white.svg";

const StyledImage = styled.figure`
  display: grid;
  background: ${props => props.theme.colorBack};
  color: ${props => props.theme.colorFront};
  text-shadow: 0 0 5rem black;

  ${props =>
    props.withSpacing &&
    css`
      margin-top: 4rem;
      margin-bottom: 4rem;

      ${media.sm(
        css`
          margin-top: 8rem;
          margin-bottom: 8rem;
        `
      )}
    `}
`;

const ImageWrapper = styled.div`
  grid-area: 1/1/1/1;
  display: flex;
`;

const Image = styled(GatsbyImage)`
  flex-grow: 1;
`;

const TextWrapper = styled.figcaption`
  grid-area: 1/1/1/1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 4rem;
  padding-bottom: 4rem;

  ${media.lg(
    css`
      padding-top: 8rem;
      padding-bottom: 8rem;
    `
  )};
`;

const TextWrapperInner = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  flex: 1 1 auto;
  max-width: 70rem;
  ${headerFontProperties};
  font-size: 3rem;

  ${media.sm(
    css`
      font-size: 5rem;
    `
  )};
  ${media.lg(
    css`
      font-size: 8rem;
    `
  )};
`;

const Logo = styled.div`
  flex: 0 0 auto;
  position: relative;
  width: 13rem;
  height: 17rem;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  margin-left: 4rem;
`;

const ImageBlock = ({ imageFluid, imageAlt, text, withSpacing = false }) => {
  const block = (
    <ColorTheme color="black">
      <StyledImage withSpacing={withSpacing}>
        <ImageWrapper>
          {imageFluid && imageAlt ? (
            <Image fluid={imageFluid} alt={imageAlt} />
          ) : null}
        </ImageWrapper>
        {text ? (
          <TextWrapper>
            <Container>
              <TextWrapperInner>
                <Text>{text}</Text>
                <Logo style={{ backgroundImage: `url(${logo})` }} />
              </TextWrapperInner>
            </Container>
          </TextWrapper>
        ) : null}
      </StyledImage>
    </ColorTheme>
  );

  return withSpacing ? <Container>{block}</Container> : block;
};

export default ImageBlock;
