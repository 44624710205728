import React from "react";
import styled, { css } from "styled-components";
import GatsbyImage from "gatsby-image";
import { ColorTheme } from "../../themes/ColorTheme";
import { headerFontProperties } from "../../../utilities/fonts";
import { media } from "../../../utilities/media";
import Container from "../../elements/Container";

const StyledHero = styled.div`
  display: grid;
  background-color: ${props => props.theme.colorBack};
  color: ${props => props.theme.colorFront};
  text-shadow: 0 0 5rem black;
`;

const ImageWrapper = styled.div`
  grid-area: 1/1/1/1;
  display: flex;
`;

const Image = styled(GatsbyImage)`
  flex-grow: 1;
`;

const TextWrapper = styled.div`
  grid-area: 1/1/1/1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  ${media.lg(
    css`
      padding-top: 10rem;
      padding-bottom: 8rem;
    `
  )};
`;

const Text = styled.div`
  width: 75%;
  max-width: 70rem;
  margin-left: auto;
  text-align: right;
`;

const Headline = styled.p`
  ${headerFontProperties};
  font-size: 5rem;

  ${media.sm(
    css`
      font-size: 8rem;
    `
  )};
  ${media.md(
    css`
      font-size: 9rem;
    `
  )};
  ${media.xl(
    css`
      font-size: 10rem;
    `
  )};
`;

const Hero = ({ imageFluid, imageAlt, headline, children }) => (
  <ColorTheme color="black">
    <StyledHero>
      <ImageWrapper>
        {imageFluid && imageAlt ? (
          <Image fluid={imageFluid} alt={imageAlt} critical fadeIn={false} />
        ) : null}
      </ImageWrapper>
      <TextWrapper>
        <Container>
          <Text>
            {headline ? <Headline>{headline}</Headline> : null}
            {children}
          </Text>
        </Container>
      </TextWrapper>
    </StyledHero>
  </ColorTheme>
);

export default Hero;
