import React from "react";
import styled from "styled-components";
import Container from "./Container";

const StyledFooter = styled.footer`
  border-top: 3px solid currentColor;
  background-color: ${props => props.theme.colorBack};
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  text-transform: uppercase;
`;

const A = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer"
})``;

const Footer = () => (
  <StyledFooter>
    <Container>
      Tämän sivun tekivät <A href="https://www.evermade.fi/">Evermade</A> ja{" "}
      <A href="https://n2.fi/">N2</A>.
    </Container>
  </StyledFooter>
);

export default Footer;
