import React from "react";
import { createGlobalStyle, css } from "styled-components";
import { media } from "../../utilities/media";
import {
  bodyFontProperties,
  headerFontProperties
} from "../../utilities/fonts";
import { BaseTheme } from "../themes/BaseTheme";
import "../../assets/fonts.css";
import WideContainer from "../elements/WideContainer";

const GlobalStyle = createGlobalStyle`${css`
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 8px;

    ${media.sm(css`
      font-size: 9px;
    `)};

    ${media.md(css`
      font-size: 9.5px;
    `)};

    ${media.lg(css`
      font-size: 10px;
    `)};
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    background-color: ${props => props.theme.colorBack};
    color: ${props => props.theme.colorFront};
    ${bodyFontProperties};
    margin: 0;
    font-size: 2rem;

    ${media.lg(css`
      font-size: 2.2rem;
    `)}
  }

  a {
    color: inherit;
  }

  img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  figure {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    ${headerFontProperties};
  }

  h1,
  h2 {
    font-size: 5rem;

    ${media.sm(
      css`
        font-size: 6rem;
      `
    )};
    ${media.lg(
      css`
        font-size: 7rem;
      `
    )};
  }
  h3 {
    font-size: 3rem;

    ${media.lg(css`
      font-size: 4rem;
    `)};
  }
  h4 {
    font-size: 2.6rem;

    ${media.lg(css`
      font-size: 3rem;
    `)};
  }

  h5,
  h6 {
    font: inherit;
    font-weight: bold;
  }
`}`;

const Page = ({ children }) => (
  <BaseTheme>
    <>
      <GlobalStyle />
      <WideContainer>{children}</WideContainer>
    </>
  </BaseTheme>
);

export default Page;
