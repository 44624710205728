import React from "react";
import { Link } from "gatsby";
import logo from "../../../assets/images/text-logo-white.svg";

const Logo = ({ to, text }) => (
  <Link to={to}>
    <img src={logo} alt={text} />
  </Link>
);

export default Logo;
