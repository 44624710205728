import styled from "styled-components";

const WysiwygHtml = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  figure {
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export default WysiwygHtml;
