import { css } from "styled-components";

export const bodyFont = "DINNext, sans-serif";
export const headerFont = "PFDINStencil, sans-serif";

export const bodyFontProperties = css`
  font-family: ${bodyFont};
`;
export const headerFontProperties = css`
  font-family: ${headerFont};
  font-weight: normal;
  line-height: 0.85;
  letter-spacing: -0.05em;
  text-transform: uppercase;
`;
