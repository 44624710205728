import React from "react";
import { graphql, withPrefix } from "gatsby";
import Page from "../components/templates/Page";
import Header from "../components/blocks/Header/";
import { ColorTheme } from "../components/themes/ColorTheme";
import Section from "../components/elements/Section";
import Hero from "../components/blocks/Hero/";
import SEO from "../components/elements/SEO";
import StructuredData from "../components/elements/StructuredData";
import ImageText from "../components/blocks/ImageText/";
import Button from "../components/elements/Button";
import Image from "../components/blocks/Image/";
import Text from "../components/blocks/Text/";
import Footer from "../components/elements/Footer";
import Columns from "../components/blocks/Columns/";
import WysiwygHtml from "../components/elements/WysiwygHtml";
import CompanyInfo from "../components/elements/CompanyInfo";
import ContentCluster from "../components/blocks/ContentCluster/";

const navigationItems = [
  { text: "Work&Meet", to: "#workmeet", lang: "en" },
  { text: "Offices", to: "#offices", lang: "en" },
  { text: "Eat&Drink", to: "#eatdrink", lang: "en" },
  { text: "Gym", to: "#gym", lang: "en" },
  { text: "Yhteystiedot", to: "#yhteystiedot" }
];

const contactButton = <Button to="#yhteystiedot">Ota yhteyttä</Button>;

const pdfButton = (
  <Button
    to={withPrefix("/downloads/Kiilakortteli.pdf")}
    isExternalLink
    download
  >
    Lataa kohteen esittely (PDF)
  </Button>
);

const routeButton = (
  <Button to="https://goo.gl/maps/erSekntCsZD2" isExternalLink>
    Katso reitti Kiilakortteliin
  </Button>
);

const page = ({ data, uri: currentPath }) => {
  const { title, description, siteUrl } = data.site.siteMetadata;

  return (
    <Page>
      <ColorTheme color="black">
        <Header
          siteName={title}
          siteNameLink={withPrefix("/")}
          navigationItems={navigationItems}
        />
      </ColorTheme>
      <main>
        <Hero
          imageFluid={data.heroImage.childImageSharp.fluid}
          imageAlt="Ulkokuva Kiilakorttelista"
          headline="Kiilaa itsesi Stadin parhaisiin tiloihin."
        />
        <ColorTheme color="brown">
          <Section id="workmeet">
            <ImageText
              imageFluid={data.aerialPhoto.childImageSharp.fluid}
              imageAlt="Ilmakuva Kiilakorttelista"
              flipped
            >
              <h2>Work&amp;Meet</h2>
              <p>
                Merellisen Ruoholahden, hektisen Baanan ja sykkivän Kampin
                kainaloon kiilautuu teollisuushenkinen kortteli, joka toimi
                kirjapaino- ja mediatalona aina 80-luvulle saakka. Nyt
                päivitämme vuonna 1952 rakennetun arkkitehtonisen helmen
                2020-luvulle.
              </p>
              <p>{contactButton}</p>
            </ImageText>
            <ImageText
              imageFluid={data.laptopPhoto.childImageSharp.fluid}
              imageAlt="Kädet kannettavan tietokoneen päällä"
            >
              <h3>Kiila on työtä, yhteistyötä ja tulosta</h3>
              <p>
                Kiila-kortteli määrittelee uusiksi työpaikan sijainnin ja
                saavutettavuuden. Kuinka moneen työpaikkaan Helsingissä pääsee
                kävellen, pyöräillen, henkilö- tai linja-autolla, raitovaunulla
                ja metrolla?
              </p>
              <p>{pdfButton}</p>
            </ImageText>
          </Section>
        </ColorTheme>
        <Image
          imageFluid={data.restaurantImage.childImageSharp.fluid}
          imageAlt="Kiilakorttelin ravintola"
          text="Kivijalka elää aamusta iltaan Kiila-korttelissa."
        />
        <ColorTheme color="green">
          <Section id="offices">
            <ImageText
              imageFluid={data.dudeWorkingImage.childImageSharp.fluid}
              imageAlt="Henkilö työskentelemässä tietokoneella"
              flipped
            >
              <h2>Offices</h2>
              <p>
                Toimistotilojen ja trendikkäiden ravintola-, kahvila- ja
                liikuntapalveluiden lisäksi Kiilasta löytyy joustavasti
                pienempiä studioita sekä erilaisia neuvottelu- ja
                projektityötiloja, joita vuokrataan kaiken kokoisille
                yrityksille. Täällä pienikin voi nauttia suuren yrityksen
                eduista.
              </p>
              <p>{contactButton}</p>
            </ImageText>
            <ImageText
              imageFluid={data.officeImage.childImageSharp.fluid}
              imageAlt="Toimistotila"
            >
              <h3>Urbaani, loft-henkinen, ydinkeskustassa</h3>
              <p>
                Vielä löytyy tilaa yrityksille, niin suurille kuin pienille,
                jotka arvostavat Kiila-korttelin henkeä. Ylintä kerrosta tulee
                hallinnoimaan maailman toiseksi suurin varustamoyhtiö. Alempana
                useampaa kerrosta asuttavat globaalin media- ja
                markkinointiyhtiön toimistot.
              </p>
              <p>{pdfButton}</p>
            </ImageText>
          </Section>
        </ColorTheme>
        <Image
          imageFluid={data.sideViewPhoto.childImageSharp.fluid}
          imageAlt="Näkymä Kiilakortteliin Ruoholahdenkadulta"
          text="Kiila-kortteli tarjoaa seitsemän kerrosta ja 10&nbsp;000 kerrosneliötä uniikkia tilaa."
        />
        <ColorTheme color="yellow">
          <Section id="eatdrink">
            <ImageText
              imageFluid={data.raneiPhoto.childImageSharp.fluid}
              imageAlt="Ruokaa lautasilla"
              flipped
            >
              <h2>Eat&amp;Drink</h2>
              <p>
                Kiila-korttelin kahvila ja moneen makuun herkkuja tarjoileva
                ravintola palvelevat aamusta iltaan sekä viikonloppuisin.
                Lähialueella on runsas tarjoama palveluja, kuten Hietalahden
                halli ja kirpputori, etniset ravintolat ja galleriat.
              </p>
              <p>{contactButton}</p>
            </ImageText>
            <Image
              imageFluid={data.cafeImage.childImageSharp.fluid}
              imageAlt="Kahvilan tiski"
              withSpacing
            />
          </Section>
        </ColorTheme>
        <Image
          imageFluid={data.gymPhoto.childImageSharp.fluid}
          imageAlt="Henkilön jalkaterät ja voimailupaino"
          text="Työ ja hyvinvointi samassa talossa"
        />
        <ColorTheme color="blue">
          <Section id="gym">
            <ImageText
              imageFluid={data.smilePhoto.childImageSharp.fluid}
              imageAlt="Hymyilevä henkilö"
              flipped
            >
              <h2>TFW Stadi</h2>
              <p>
                Kiila-korttelin käyttäjiä ja ulkopuolisia palvelee
                pääkaupunkiseudun neljäs, intohimolla treenaavien
                kaupunkilaisten suosioon noussut Training for Warriors -sali.
              </p>
              <p>{contactButton}</p>
            </ImageText>
            <Image
              imageFluid={data.trainingPhoto.childImageSharp.fluid}
              imageAlt="Ihmisiä harrastamassa liikuntaa"
              withSpacing
            />
          </Section>
        </ColorTheme>
        <Image
          imageFluid={data.map.childImageSharp.fluid}
          imageAlt="Kartta Kiilakorttelin sijainnista"
        />
        <ColorTheme color="green">
          <Section id="yhteystiedot">
            <Text>
              <h2>Yhteystiedot</h2>
              <h3>Kiilakorttelin tilojen vuokraus ja tiedustelut</h3>
            </Text>
            <WysiwygHtml>
              <Columns
                columns={[
                  <>
                    <h4>Kirsi Zeddini</h4>
                    <p>
                      <span lang="en">Leasing Manager</span>
                      <br />
                      <a href="tel:+358405154369">040 515 4369</a>
                      <br />
                      <a href="mailto:kirsi.zeddini@antilooppi.fi">
                        kirsi.zeddini@antilooppi.fi
                      </a>
                    </p>
                  </>,
                  <>
                    <h4>Mari Härkönen</h4>
                    <p>
                      <span lang="en">Leasing Manager</span>
                      <br />
                      <a href="tel:+358503755531">050 375 5531</a>
                      <br />
                      <a href="mailto:mari.harkonen@antilooppi.fi">
                        mari.harkonen@antilooppi.fi
                      </a>
                    </p>
                  </>,
                  <>
                    <h4>Tero Nyström</h4>
                    <p>
                      <span lang="en">Director, Business development</span>
                      <br />
                      <a href="tel:+358 50 427 3889">050 427 3889</a>
                      <br />
                      <a href="mailto:tero.nystrom@antilooppi.fi">
                        tero.nystrom@antilooppi.fi
                      </a>
                    </p>
                  </>
                ]}
              />
              <ContentCluster items={[pdfButton, routeButton]} />
            </WysiwygHtml>
            <CompanyInfo />
          </Section>
        </ColorTheme>
      </main>

      <SEO
        title={title}
        description={description}
        image={`${siteUrl}${withPrefix("/downloads/kiilakortteli.jpg")}`}
        imageAlt="Ulkokuva Kiilakorttelista"
        url={`${siteUrl}${currentPath}`}
      />
      <StructuredData
        data={{
          "@context": "https://schema.org",
          "@type": "Place",
          "@id": `${siteUrl}${withPrefix("/#place")}`,
          name: title,
          description: description,
          url: `${siteUrl}${withPrefix("/")}`,
          image: `${siteUrl}${withPrefix("/downloads/kiilakortteli.jpg")}`,
          sameAs:
            "https://antilooppi.fi/toimitilakiinteistot/helsingin-ruoholahdenkatu-21-10044",
          address: {
            "@type": "PostalAddress",
            streetAddress: "Ruoholahdenkatu 21",
            postalCode: "00180",
            addressLocality: "Helsinki",
            addressCountry: "FI"
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 60.16459,
            longitude: 24.92388
          }
        }}
      />

      <ColorTheme color="green">
        <Footer />
      </ColorTheme>
    </Page>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    heroImage: file(relativePath: { eq: "00_a_kiila_herokuva.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, maxHeight: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aerialPhoto: file(relativePath: { eq: "01_a_kiila_ilmasta.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 676, maxHeight: 470, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    laptopPhoto: file(relativePath: { eq: "01_a_kiila_laptop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 676, maxHeight: 470, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    restaurantImage: file(relativePath: { eq: "01_a_kiila_ravintola.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, maxHeight: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dudeWorkingImage: file(relativePath: { eq: "02_a_kiila_work.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 676, maxHeight: 470, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    officeImage: file(relativePath: { eq: "02_a_kiila_office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 676, maxHeight: 470, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sideViewPhoto: file(relativePath: { eq: "00_a_kiila_ulkokuva.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, maxHeight: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    raneiPhoto: file(relativePath: { eq: "03_a_ruoka1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 676, maxHeight: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gymPhoto: file(relativePath: { eq: "04_sali2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, maxHeight: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smilePhoto: file(relativePath: { eq: "04_a_nainen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 676, maxHeight: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cafeImage: file(relativePath: { eq: "03_a_ruoka3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, maxHeight: 470, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    trainingPhoto: file(relativePath: { eq: "04_sali1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, maxHeight: 470, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    map: file(
      relativePath: { eq: "Kiilakortteli_Kartta_01_Lev_1440px-1-min.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default page;
