import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";

const SEO = ({ title, description, lang, image, imageAlt, url }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          buildTime
          siteMetadata {
            defaultLang: lang
            siteTitle: title
          }
        }
      }
    `}
    render={data => {
      const { buildTime } = data.site;
      const { defaultLang, siteTitle } = data.site.siteMetadata;

      return (
        <Helmet>
          <html lang={lang || defaultLang} />
          {title ? <title>{title}</title> : null}
          {title ? <meta property="og:title" content={title} /> : null}
          {description ? (
            <meta name="description" content={description} />
          ) : null}
          {description ? (
            <meta property="og:description" content={description} />
          ) : null}
          {image ? <meta property="og:image" content={image} /> : null}
          {image && imageAlt ? (
            <meta name="og:image:alt" content={imageAlt} />
          ) : null}
          {image && imageAlt ? (
            <meta name="twitter:image:alt" content={imageAlt} />
          ) : null}
          {url ? <meta property="og:url" content={url} /> : null}
          <meta property="og:updated_time" content={buildTime} />
          <meta property="og:site_name" content={siteTitle} />
          {image ? (
            <meta name="twitter:card" content="summary_large_image" />
          ) : null}
          <meta name="facebook-domain-verification" content="rvdhpu4ljqruefn67w5hmsozpsl67w" />
        </Helmet>
      );
    }}
  />
);

export default SEO;
