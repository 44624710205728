import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../../utilities/media";
import Container from "../../elements/Container";

const StyledContentCluster = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;

  ${media.sm(
    css`
      margin-top: 8rem;
      margin-bottom: 8rem;
    `
  )}
`;

const Cluster = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
`;

const Item = styled.div`
  flex: 0 0 auto;
  margin: 1rem;
`;

const ContentCluster = ({ items }) => (
  <StyledContentCluster>
    <Container>
      <Cluster>
        {items.map((item, i) => (
          <Item key={i}>{item}</Item>
        ))}
      </Cluster>
    </Container>
  </StyledContentCluster>
);

export default ContentCluster;
