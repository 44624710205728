import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { easeOutQuint } from "../../utilities/easings";

const StyledButton = styled(Link)`
  display: inline-block;
  color: ${props => props.theme.colorFront};
  border: solid 3px ${props => props.theme.colorFront};
  padding: 0.7em 1.7em;
  font-weight: 700;
  border-radius: 0;
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
  transition: all 250ms ${easeOutQuint};

  &:hover {
    background-color: ${props => props.theme.colorFront};
    color: ${props => props.theme.colorBack};
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
`;

const Button = ({ to, children, isExternalLink = false, ...attrs }) =>
  isExternalLink ? (
    <StyledButton
      as="a"
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      {...attrs}
    >
      {children}
    </StyledButton>
  ) : (
    <StyledButton to={to} {...attrs}>
      {children}
    </StyledButton>
  );

export default Button;
