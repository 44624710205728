import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../utilities/media";
import Container from "./Container";
import WysiwygHtml from "./WysiwygHtml";
import logo from "../../assets/images/antilooppi.svg";

const StyledInfo = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;

  ${media.sm(
    css`
      margin-top: 8rem;
      margin-bottom: 8rem;
    `
  )}
`;

const Columns = styled.div`
  align-items: flex-end;
  justify-content: space-between;
  margin: -1rem;

  ${media.sm(
    css`
      display: flex;
    `
  )};
`;

const Text = styled.div`
  flex: 0 1 65%;
  padding: 1rem;
`;

const Logo = styled.div`
  flex: 0 0 auto;
  order: 1;
  padding: 1rem;
`;

const Img = styled.img`
  width: 19rem;
`;

const CompanyInfo = () => (
  <StyledInfo>
    <Container>
      <Columns>
        <Logo>
          <Img src={logo} alt="Antiloopin logo" />
        </Logo>
        <Text>
          <WysiwygHtml>
            <p>
              Antilooppi on suomalainen, itsenäinen kiinteistösijoitusyhtiö,
              joka kehittää kiinteistöjään vastuullisesti ja pitkäjänteisesti.
              Antilooppi on luovien ja toimivien tilaratkaisujen mahdollistaja.
            </p>
            <p>
              <b>
                <a
                  href="https://www.antilooppi.fi/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.antilooppi.fi
                </a>
              </b>
            </p>
          </WysiwygHtml>
        </Text>
      </Columns>
    </Container>
  </StyledInfo>
);

export default CompanyInfo;
