import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../../utilities/media";
import Container from "../../elements/Container";

const StyledColumns = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;

  ${media.sm(
    css`
      margin-top: 8rem;
      margin-bottom: 8rem;
    `
  )}
`;

const ColumnList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -2rem;
`;

const Column = styled.div`
  flex-grow: 1;
  margin: 2rem;
`;

const Columns = ({ columns }) => (
  <StyledColumns>
    <Container>
      <ColumnList>
        {columns.map((column, i) => (
          <Column key={i}>{column}</Column>
        ))}
      </ColumnList>
    </Container>
  </StyledColumns>
);

export default Columns;
