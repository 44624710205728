import { css } from "styled-components";

const BREAKPOINT_SM = 576;
const BREAKPOINT_MD = 768;
const BREAKPOINT_LG = 992;
const BREAKPOINT_XL = 1200;

/**
 * Wraps your CSS in a media query with predefined breakpoint.
 */
export const media = {
  xsMax: (...args) =>
    css`
      @media (max-width: ${BREAKPOINT_SM - 1}px) {
        ${css(...args)}
      }
    `,
  sm: (...args) =>
    css`
      @media (min-width: ${BREAKPOINT_SM}px) {
        ${css(...args)}
      }
    `,
  smMax: (...args) =>
    css`
      @media (max-width: ${BREAKPOINT_MD - 1}px) {
        ${css(...args)}
      }
    `,
  md: (...args) =>
    css`
      @media (min-width: ${BREAKPOINT_MD}px) {
        ${css(...args)}
      }
    `,
  mdMax: (...args) =>
    css`
      @media (max-width: ${BREAKPOINT_LG - 1}px) {
        ${css(...args)}
      }
    `,
  lg: (...args) =>
    css`
      @media (min-width: ${BREAKPOINT_LG}px) {
        ${css(...args)}
      }
    `,
  lgMax: (...args) =>
    css`
      @media (max-width: ${BREAKPOINT_XL - 1}px) {
        ${css(...args)}
      }
    `,
  xl: (...args) =>
    css`
      @media (min-width: ${BREAKPOINT_XL}px) {
        ${css(...args)}
      }
    `
};
