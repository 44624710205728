import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../../utilities/media";
import Container from "../../elements/Container";
import WysiwygHtml from "../../elements/WysiwygHtml";

const StyledText = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;

  ${media.sm(
    css`
      margin-top: 8rem;
      margin-bottom: 8rem;
    `
  )}
`;

const Text = ({ children }) => (
  <StyledText>
    <Container>
      <WysiwygHtml>{children}</WysiwygHtml>
    </Container>
  </StyledText>
);

export default Text;
