import styled from "styled-components";

const Section = styled.section`
  color: ${props => props.theme.colorFront};
  background-color: ${props => props.theme.colorBack};

  &::before,
  &::after {
    content: "";
    display: block;
    height: 1px;
  }

  &::before {
    margin-bottom: -1px;
  }

  &::after {
    margin-top: -1px;
  }
`;

export default Section;
