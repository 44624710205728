import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../../utilities/media";
import { zIndexHeader } from "../../../utilities/z-index";
import Logo from "./Logo";
import Navigation from "./Navigation";
import Container from "../../elements/Container";

const StyledHeader = styled.header`
  color: ${props => props.theme.colorFront};
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: ${props => props.theme.colorBack};
  z-index: ${zIndexHeader};

  ${props =>
    props.takesSpace
      ? null
      : css`
          ${media.lg(
            css`
              background-color: transparent;
              position: absolute;
              width: 100%;
              left: 0;
            `
          )}
        `}
`;

const Bar = styled.div`
  align-items: center;
  justify-content: space-between;
  margin: -2rem;

  ${media.lg(
    css`
      display: flex;
    `
  )}
`;

const BarItem = styled.div`
  padding: 2rem;
`;

const Header = ({ siteName, siteNameLink, navigationItems, takesSpace }) => (
  <StyledHeader takesSpace={takesSpace}>
    <Container>
      <Bar>
        <BarItem>
          <Logo to={siteNameLink} text={siteName} />
        </BarItem>
        {navigationItems ? (
          <BarItem>
            <Navigation items={navigationItems} />
          </BarItem>
        ) : null}
      </Bar>
    </Container>
  </StyledHeader>
);

export default Header;
