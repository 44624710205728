import React from "react";
import styled, { css } from "styled-components";
import Image from "gatsby-image";
import { media } from "../../../utilities/media";
import WysiwygHtml from "../../elements/WysiwygHtml";
import Container from "../../elements/Container";

const StyledImageText = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;

  ${media.sm(
    css`
      margin-top: 8rem;
      margin-bottom: 8rem;
    `
  )}

  ${media.md(css`
    display: flex;
  `)};
`;

const Column = styled.div`
  flex: 1 0;

  ${props =>
    props.wideText
      ? css`
          flex-grow: 2;
        `
      : null}

  ${props =>
    props.centered
      ? css`
          align-self: center;
        `
      : null}

  ${props =>
    props.flipped
      ? css`
          order: -1;
        `
      : null}
`;

const TextWrapper = styled.div`
  ${props =>
    props.flipped
      ? css`
          ${media.md(css`
            margin-right: 4rem;
          `)};
          ${media.lg(css`
            margin-right: 6rem;
          `)};
        `
      : css`
          ${media.md(css`
            margin-left: 4rem;
          `)};
          ${media.lg(css`
            margin-left: 6rem;
          `)};
        `};

  ${media.smMax(css`
    margin-top: 4rem;
  `)};
`;

const ImageText = ({ imageFluid, imageAlt, children, flipped, wideText }) => (
  <Container>
    <StyledImageText>
      <Column>
        {imageFluid && imageAlt ? (
          <Image fluid={imageFluid} alt={imageAlt} />
        ) : null}
      </Column>
      <Column flipped={flipped} wideText={wideText} centered>
        <TextWrapper flipped={flipped}>
          <WysiwygHtml>{children}</WysiwygHtml>
        </TextWrapper>
      </Column>
    </StyledImageText>
  </Container>
);

export default ImageText;
